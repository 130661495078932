<template>
  <div class="mt-0-px pb-0" v-if="!isExtern">
    <div id="options" v-if="angemeldeterMitarbeiter">
      <navigation-arvea :angemeldeterMitarbeiter="angemeldeterMitarbeiter" />
      <!-- BANNER 
      <div class="row banner">
        <div class="col-3"></div>
        <div class="col-6">
          <h1>ARVEA Universe Options</h1>
        </div>
        <div class="col-3">
          <img src="https://arvea-dev.batix.ch/pic/180D26153DB_241x206.png" />
        </div>
      </div>-->

      <!-- TITLE -->
      <div class="row mx-auto title">
        <div class="col-xl-12 d-flex flex-column">
          <h1 class="px-3">Options</h1>
          <!--<span class="px-3"
            >Untertitel</span
          >-->
        </div>
      </div>

      <!-- TABLE -->
      <div class="row m-auto" style="max-width: 85%">
        <div class="col-xl-12 d-flex align-items-center my-3">
          <div class="row col-xl-12">
            <div class="col-xl-12 my-5">
              <div class="scrollable-table">
                <b-table
                  class="optionsTable"
                  ref="selectableTable"
                  tbody-tr-class="item"
                  :items="list"
                  :fields="listFields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :sort-compare="sortDates"
                  :busy="isBusy"
                  striped
                  hover
                  sort-icon-left
                  fixed
                  selectable
                  borderless
                  select-mode="multi"
                  @row-clicked="details"
                >
                  <template #head()="{ label, field: { key } }">
                    {{ label }}
                    <template>
                      <svg
                        v-if="sortBy !== key"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        style="
                          width: 0.65rem;
                          height: 1rem;
                          fill: #213056;
                          margin: 0 0 3px 5px;
                        "
                      >
                        <path
                          d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"
                        />
                      </svg>
                      <svg
                        v-else-if="
                          (key != 'symbol' && sortDesc) ||
                            (key == 'symbol' && !sortDesc)
                        "
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        style="
                          width: 0.65rem;
                          height: 1rem;
                          fill: #213056;
                          margin: -7px 0 0 5px;
                        "
                      >
                        <path
                          d="M311.9 335.1l-132.4 136.8C174.1 477.3 167.1 480 160 480c-7.055 0-14.12-2.702-19.47-8.109l-132.4-136.8C-9.229 317.8 3.055 288 27.66 288h264.7C316.9 288 329.2 317.8 311.9 335.1z"
                        />
                      </svg>
                      <svg
                        v-else-if="key == 'symbol' && sortDesc"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        style="
                          width: 0.65rem;
                          height: 1rem;
                          fill: #213056;
                          margin: 0 0 0 5px;
                        "
                      >
                        <path
                          d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z"
                        />
                      </svg>
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        style="
                          width: 0.65rem;
                          height: 1rem;
                          fill: #213056;
                          margin: 0 0 0 5px;
                        "
                      >
                        <path
                          d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z"
                        />
                      </svg>
                    </template>
                  </template>
                  <template #row-details="row">
                    <div class="scrollable-details-table">
                      <b-table
                        ref="table"
                        tbody-tr-class="item"
                        :items="row.item.dates"
                        :fields="allOpenRowsFields"
                        :sort-by.sync="detailSortBy"
                        :sort-desc.sync="detailSortDesc"
                        :sort-compare="sortDates"
                        :busy="isBusy"
                        select-mode="range"
                        class="detailTable"
                      >
                        <template #head()="{ label, field: { key } }">
                          {{ label }}
                          <template>
                            <svg
                              v-if="detailSortBy !== key"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              style="
                                width: 0.65rem;
                                height: 1rem;
                                fill: #213056;
                                margin: 0 0 3px 5px;
                              "
                            >
                              <path
                                d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"
                              />
                            </svg>
                            <svg
                              v-else-if="detailSortDesc"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              style="
                                width: 0.65rem;
                                height: 1rem;
                                fill: #213056;
                                margin: -7px 0 0 5px;
                              "
                            >
                              <path
                                d="M311.9 335.1l-132.4 136.8C174.1 477.3 167.1 480 160 480c-7.055 0-14.12-2.702-19.47-8.109l-132.4-136.8C-9.229 317.8 3.055 288 27.66 288h264.7C316.9 288 329.2 317.8 311.9 335.1z"
                              />
                            </svg>
                            <svg
                              v-else
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              style="
                                width: 0.65rem;
                                height: 1rem;
                                fill: #213056;
                                margin: 0 0 0 5px;
                              "
                            >
                              <path
                                d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z"
                              />
                            </svg>
                          </template>
                        </template>
                      </b-table>
                    </div>
                  </template>
                </b-table>
                <infinite-loading
                  :identifier="infiniteId"
                  @infinite="infiniteHandler"
                >
                  <div slot="no-more"></div>
                  <div slot="no-results"></div>
                </infinite-loading>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import page from "@/mixins/Page";
import store from "@/store";
import Api from "@/Api";
import InfiniteLoading from "vue-infinite-loading";
import NavigationArvea from "@/components/Navigation/NavigationArvea";

export default {
  name: "Options",
  components: { InfiniteLoading, NavigationArvea },
  mixins: [page],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {
        return "Arvea Universe Options";
      }
    };
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 50,
      list: [],
      allOpenRows: [],
      ladeFehler: false,
      infiniteId: +new Date(),
      sortBy: "",
      sortDesc: true,
      detailSortBy: "date",
      detailSortDesc: true,
      sortDirection: "desc",
      isBusy: false,
      allOpenRowsFields: [
        {
          key: "date",
          sortable: true,
          label: "Date",
          variant: "arvea-medium"
        },
        {
          key: "totalVolumeCalls",
          sortable: true,
          label: "Total Volume Calls",
          class: "text-right",
          variant: "arvea-medium",
          formatter: value => {
            return this.formatNum(value);
          }
        },
        {
          key: "totalVolumePuts",
          sortable: true,
          label: "Total Volume Puts",
          class: "text-right",
          variant: "arvea-medium",
          formatter: value => {
            return this.formatNum(value);
          }
        },
        {
          key: "deltaPut",
          sortable: true,
          label: "Δ Put",
          class: "text-center",
          variant: "arvea-medium",
          formatter: value => {
            return this.formatNum(value);
          }
        },
        {
          key: "deltaCall",
          sortable: true,
          label: "Δ Call",
          class: "text-center",
          variant: "arvea-medium",
          formatter: value => {
            return this.formatNum(value);
          }
        },
        {
          key: "callPercentage",
          sortable: true,
          label: "Call %",
          class: "text-center",
          variant: "arvea-medium",
          formatter: value => {
            if (value != null) {
              return value;
            } else {
              return "-";
            }
          }
        },
        {
          key: "putPercentage",
          sortable: true,
          label: "Put %",
          class: "text-center",
          variant: "arvea-medium",
          formatter: value => {
            if (value != null) {
              return value;
            } else {
              return "-";
            }
          }
        },
        {
          key: "avg",
          sortable: true,
          label: "Ø",
          class: "text-center",
          variant: "arvea-medium",
          formatter: value => {
            if (value != null) {
              return value;
            } else {
              return "-";
            }
          }
        },
        {
          key: "scale",
          sortable: true,
          label: "Letter",
          class: "text-center",
          variant: "arvea-medium",
          formatter: value => {
            if (value != null) {
              return value;
            } else {
              return "-";
            }
          }
        },
        {
          key: "ie",
          sortable: true,
          label: "IE",
          class: "text-center",
          thClass: "table-arvea-medium",
          tdClass: (value, key, item) => {
            return this.getIEDetailColor(value);
          },
          formatter: value => {
            if (value != null) {
              return value;
            } else {
              return "-";
            }
          }
        },
        {
          key: "cie",
          sortable: true,
          label: "P-IE",
          class: "text-center",
          thClass: "table-arvea-medium",
          tdClass: (value, key, item) => {
            return this.getIEDetailColor(value);
          },
          formatter: value => {
            if (value != null) {
              return value;
            } else {
              return "-";
            }
          }
        },
        {
          key: "pie",
          sortable: true,
          label: "C-IE",
          class: "text-center",
          thClass: "table-arvea-medium",
          tdClass: (value, key, item) => {
            return this.getIEDetailColor(value);
          },
          formatter: value => {
            if (value != null) {
              return value;
            } else {
              return "-";
            }
          }
        }
      ],
      listFields: [
        {
          key: "symbol",
          sortable: true,
          label: "Symbol"
        },
        {
          key: "dates[0].ie",
          sortable: true,
          label: "IE",
          class: "text-center",
          tdClass: (value, key, item) => {
            return this.getIEColor(value);
          },
          formatter: value => {
            if (value != null) {
              return value;
            } else {
              return "-";
            }
          }
        },
        {
          key: "dates[0].totalVolumeCalls",
          sortable: true,
          label: "Total Volume Calls",
          class: "text-right",
          formatter: value => {
            return this.formatNum(value);
          }
        },
        {
          key: "dates[0].totalVolumePuts",
          sortable: true,
          label: "Total Volume Puts",
          class: "text-right",
          formatter: value => {
            return this.formatNum(value);
          }
        },
        {
          key: "universeList",
          sortable: true,
          label: "Universes",
          class: "text-right"
        }
      ]
    };
  },
  watch: {},
  computed: {
    permissions() {
      return this.$store.state.permissions;
    }
  },
  created() {},
  mounted() {},
  methods: {
    infiniteHandler($state) {
      Api.get("calculateddata/", {}).then(response => {
        const data = response.data;
        if (data.symbols.length === this.anzahlProPage) {
          this.page += 1;
          this.list.push(...data.symbols);
          $state.loaded();
        } else {
          this.list.push(...data.symbols);
          $state.complete();
        }
      });
    },
    details(item) {
      Object.entries(this.allOpenRows).map(ele => {
        if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, "_showDetails", !ele._showDetails);
        }
      });

      this.allOpenRows = {};
      this.$set(item, "_showDetails", !item._showDetails);
      let test = [];
      item.dates.forEach(element => {
        test.push(element);
      });
      this.allOpenRows[item.symbol] = test;
    },
    startseite() {
      let startseite = this.$router.resolve({ name: "StartseiteArvea" });
      window.location.href = startseite.href;
    },
    routeOptions() {
      window.location.href = this.$router.resolve({ name: "Options" }).href;
    },
    routeCalculator() {
      let calculator = this.$router.resolve({ name: "Calculator" });
      window.location.href = calculator.href;
    },
    routeETF() {
      window.location.href = this.$router.resolve({ name: "ETF" }).href;
    },
    routePortfolio() {
      window.location.href = this.$router.resolve({ name: "Portfolio" }).href;
    },
    getIEDetailColor(value) {
      if (value && value > 10.0) {
        return "table-success";
      } else {
        return "table-arvea-medium";
      }
    },
    getIEColor(value) {
      if (value && value > 10.0) {
        return "table-success";
      } else {
        return "";
      }
    },

    formatNum(num) {
      if (num != null) {
        if (typeof num == "number") {
          var str = num.toLocaleString("en-US");
          str = str.replace(/\./, ".");
          str = str.replace(/,/g, "'");
          return str;
        } else return num;
      } else {
        return "-";
      }
    },

    sortDates(datumA, datumB, key) {
      if (key != "date") {
        return false;
      } else {
        let a = null;
        let b = null;

        if (key.includes(".")) {
          a = this.resolve(key, datumA);
          b = this.resolve(key, datumB);
        } else {
          a = datumA[key];
          b = datumB[key];
        }
        if (a && b) {
          a = a.split(".");
          b = b.split(".");
          a =
            parseInt(a[2], 10) * 10000 +
            parseInt(a[1], 10) * 100 +
            parseInt(a[0]);
          b =
            parseInt(b[2], 10) * 10000 +
            parseInt(b[1], 10) * 100 +
            parseInt(b[0]);
          return a - b;
        } else {
          return false;
        }
      }
    }
  }
};
</script>
<style lang="scss">
body {
  background: white;
  color: #213056;
  overflow-x: hidden;
}
#options .scrollable-table {
  height: 580px;
  overflow-y: scroll;
}
#options .scrollable-details-table {
  height: 460px;
  overflow-y: scroll;
}

#options .title {
  position: relative;
  z-index: -1;
  margin-bottom: 10px;
  padding: 45px 7.5%;
  background: #a6ccd7;
  /*background: linear-gradient(
    90deg,
    rgba(166, 204, 215, 0.8) 10%,
    #a6ccd7 100%
  );*/
}
#options .title h1 {
  font-size: 36px;
}
#options .title span {
  font-size: 17px;
}
#options .banner {
  background-color: #c6ccdd;
  height: 300px;
  width: 100vw;
}
#options .banner h1 {
  padding-top: 65px;
  font-weight: 700;
  color: #9099b0;
}
#options .banner p {
  padding-top: 10px;
  font-size: 16px;
  padding-right: 30%;
}
#options .banner > div:last-child {
  display: flex;
  align-items: center;
}
#options th {
  cursor: pointer;
}
#options .optionsTable {
  cursor: pointer;
}
#options .detailTable th {
  cursor: pointer;
}
#options .detailTable {
  cursor: default;
}
/*
tr:nth-child(odd) {
  background-color: #c6ccdd;
}

tr:nth-child(even) {
  background-color: white;
}

th {
  background-color: white;
}

tr[th] {
  margin-bottom: 20px;
}

.detailTable tr:nth-child(odd) {
  background-color: #9099b0;
}

.detailTable tr:nth-child(even) {
  background-color: rgb(255, 255, 255);
}

.detailTable th {
  background-color: #c6ccdd;
}

.table-hover tbody tr:hover,
.table-hover tbody tr:hover {
  background-color: #8f98ae;
}

.detailTable .table-hover tbody tr:hover,
.detailTable .table-hover tbody tr:hover {
  background-color: yellow;
}*/
</style>
